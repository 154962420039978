<template>
    <div class="row no-gutters text-center notifications-table">
        <div class="notf-icon" :class="{'active': emailNotification}">
            <i class='bx bx-envelope'
               :id="`email-notification-${this.notifications.email ? this.notifications.email.id : 'null'}`"
            ></i>
            <b-tooltip
                    v-if="emailNotification"
                    placement="bottomleft"
                    custom-class="ps-tooltip ps-tooltip--table"
                    :target="`email-notification-${this.notifications.email ? this.notifications.email.id : 'null'}`">
                Unread message at: <br/><strong>{{ this.notifications.email.createdDate | formatDatetime }}</strong>
            </b-tooltip>
        </div>
        <div class="notf-icon" :class="{'active': showAssignedNotification}">
            <i class='bx bx-link-external'
                :id="`ext-notification-${this.notifications.assigned ? this.notifications.assigned.id : 'null'}`"
                :class="{'theme-color': showAssignedNotification}"
            ></i>
            <b-tooltip
                v-if="showAssignedNotification"
                placement="bottomleft"
                custom-class="ps-tooltip ps-tooltip--table"
                :target="`ext-notification-${this.notifications.assigned ? this.notifications.assigned.id : 'null'}`">
                New assessment assigned from {{ notificationAssignedBy }}.
            </b-tooltip>
        </div>
        <div class="notf-icon">
            <i class='bx bx-task'></i>
        </div>
        <div class="notf-icon">
            <i class='bx bx-flag'></i>
        </div>
    </div>
</template>
<script>
    /*eslint-disable */
    export default {
        name: 'notification-on-table',
        props: {
            notifications: {
                type: [Object, Array],
                default() {
                    return [];
                },
            },
        },
        data: function () {
            return {};
        },
        mounted() {
            // console.log(this.notifications)
        },
        filters: {
            formatExtension(time) {
                time = Number(time);
                switch (time) {
                    case 30:
                        return `30 mins`;
                        break;
                    case 60:
                        return `1 hour`;
                        break;
                    case 90:
                        return `1.5 hour`;
                        break;
                    case 120:
                        return `2 hour`;
                        break;
                    default:
                        return time;
                }
            }
        },
        computed: {
            notificationAssignedBy() {
                if (!this.showAssignedNotification) {
                    return null;
                }
                if (this.notifications.assigned.fromCompany) {
                    return this.notifications.assigned.fromCompany;
                }
                if (this.notifications.assigned.fromUser) {
                    return this.notifications.assigned.fromUser;
                }
                return null;
            },
            showAssignedNotification() {
                return !!this.notifications && !!this.notifications.assigned;
            },
            // rfq() {
            //     return this.items.item;
            // },
            // externalNotificationActive() {
            //     // return true; // un-comment for testing
            //     return this.rfq.notification && this.rfq.notification.time;
            // }
            emailNotification() {
                return !!this.notifications && !!this.notifications.email;
            }
        }

    };
</script>
<style>
    .notifications-table .bx {
        font-size: 20px;
        opacity: 0.5;
        color: #1C1F39;
    }

    .notifications-table .active .bx {
        opacity: 1;
    }

    .notifications-table .notf-icon {
        width: 20px;
        height: 16px;
        position: relative;
        display: inline-block;
    }

    .notifications-table .notf-icon .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: white;
        color: black;
        border: 1px solid #5E79FF;
        border-radius: 3px;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
        position: absolute;
        z-index: 1;
        text-align: center;
        padding: 5px;
        margin-left: -94px;
    }

    .notifications-table .notf-icon .tooltip-text.pos-top {
        bottom: 150%;
    }

    .notifications-table .notf-icon .tooltip-text.pos-top:after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #5E79FF transparent transparent transparent;
    }

    .notifications-table .notf-icon .tooltip-text.pos-bottom {
        top: 150%;
    }

    .notifications-table .notf-icon .tooltip-text.pos-bottom:after {
        content: ' ';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #5E79FF transparent;
    }

    .notifications-table .notf-icon:hover .tooltip-text {
        visibility: visible;
    }

    /* active styles */
    /* external active */
    .notifications-table .notf-icon.active .bx-link-external {

    }

</style>
