<script>
    /* eslint-disable */

    import NumberFormatter from "@/components/number-formatter";
    import AdvancedSearch from "@/components/utility/advanced-search";
    import Multiselect from "vue-multiselect";
    import _ from "lodash";
    import {mapGetters} from "vuex";

    export default {
        name: 'block-assessment-assessment-information',
        props: {
            assessorsList: {
                type: Array,
                default() {
                    return [];
                }
            },
            assessment: {
                type: Object,
                default() {
                    return {
                        number: '',
                        assessorName: '',
                        id: 0,
                        assessmentValue: 0,
                        assessorId: 0,
                        assessmentDate: null,
                        dateSettled: null,
                        legalRef: '',
                        legalFirmId: 0,
                    };
                }
            },
            legalFirms: {
                type: Array,
                default() {
                    return [];
                }
            },
            isShow: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                legalFirmName: '',
            };
        },
        computed: {
            ...mapGetters({
                isUserRoleAssessor: 'currentUser/isRoleAssessor',
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),
            computedAssessor: {
                get() {
                    return _.find(this.assessorsList, item => {
                        return Number(item.id) === Number(this.assessment.assessorId);
                    });
                },
                set(value) {
                    this.assessment.assessorId = value.id;
                }
            },
            computedLegalRef: {
                get() {
                    return this.assessment.legalRef;
                },
                set(val) {
                    val = val.toUpperCase();
                    //this.assessment.legalRef = val.replace(/([^a-zA-Z0-9]+)/, '');  //remove this by #126 task
                    this.assessment.legalRef = val;
                }
            },
            computedLegalFirm: {
                get() {
                    return this.legalFirmName;
                },
                set(val) {
                    this.legalFirmName = val;
                },
            },
            assessorName() {
                return (this.isShow && this.assessment && this.assessment.assessorName) ? this.assessment.assessorName : '';
            }
        },
        methods: {
            changeLegalFirm(val) {
                let lf = _.find(this.legalFirms, item => {
                    return Number(item.id) === Number(val);
                });
                if (lf && lf.name) {
                    this.legalFirmName = lf.name;
                } else {
                    this.legalFirmName = '';
                }
            },
            onSelectAssessorName(val) {
            },
            onLegalFirmSelect(val) {
                if (val) {
                    this.assessment.legalFirmId = val.id;
                    this.legalFirmName = val.name;
                    //this.onAssessmentLegalFirmChanged(val);
                } else {
                    this.$emit('new-legal-firm', this.legalFirmName);
                }
                console.log('onLegalFirmSelect', val);
            },
            onLegalFirmUpdate(val) {
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.changeLegalFirm(this.assessment.legalFirmId);
            });
        },
        watch: {
            'assessment.legalFirmId': {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.changeLegalFirm(newVal);
                }
            }
        },
        components: {
            NumberFormatter,
            AdvancedSearch,
            Multiselect,
        },
    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Assessment Information</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Assessment Nbr</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="assessment.number" class="form-control" placeholder="" readonly>
                </div>
            </div>
            <div class="form-group row">
                <label for="assessorName" class="col-sm-4 col-4 col-form-label">Assessor Name</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            v-model="computedAssessor"
                            :options="assessorsList"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Assessor"
                            @select="onSelectAssessorName"
                            :disabled="isUserRoleLawyer"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label for="assessmentDate" class="col-sm-4 col-4 col-form-label">Assessment Date</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            id="assessmentDate"
                            v-model="assessment.assessmentDate"
                            today-button
                            reset-button
                            close-button
                            class="mb-2"
                            locale="en-GB"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
                            :disabled="isUserRoleLawyer"
                    ></b-form-datepicker>
                </div>
            </div>

            <div class="form-group row">
                <label for="assessmentValue" class="col-sm-4 col-4 col-form-label">Assessment Invoice</label>
                <div class="col-sm-8 col-8">
                    <number-formatter
                            id="assessmentValue"
                            v-model="assessment.assessmentValue"
                            type="text"
                            class="form-control numeric"
                            format="$0,0.00"
                            :isDisabled="isUserRoleAssessor ||isUserRoleLawyer"
                            :isTotalManual="isUserRoleAssessor ||isUserRoleLawyer"
                    ></number-formatter>
                </div>
            </div>

            <div class="form-group row">
                <label for="assessmentValue" class="col-sm-4 col-4 col-form-label">Date with Recoveries</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            v-model="assessment.withRecoveriesOn"
                            class="mb-2 "
                            locale="en-AU"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
                            :disabled="isUserRoleAssessor ||isUserRoleLawyer"
                    ></b-form-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date Settled</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            v-model="assessment.dateSettled"
                            class="mb-2 "
                            locale="en-AU"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
                            :disabled="isUserRoleAssessor ||isUserRoleLawyer"
                    ></b-form-datepicker>
                </div>
            </div>
            <div class="form-group row">
                <label for="legalRef" class="col-sm-4 col-4 col-form-label">Legal Ref</label>
                <div class="col-sm-8 col-8">
                    <input type="text" id="legalRef" v-model="computedLegalRef" class="form-control" placeholder=""  :disabled="isUserRoleAssessor ||isUserRoleLawyer">
                </div>
            </div>
            <div class="form-group row">
                <label for="legalRef" class="col-sm-4 col-4 col-form-label">Legal Firm</label>
                <div class="col-sm-8 col-8">
                    <advanced-search v-if="!isUserRoleAssessor && !isUserRoleLawyer" ref="assessment.legalFirm"
                                     searchLabel="Legal Firm"
                                     v-model="computedLegalFirm"
                                     :searchData="legalFirms"
                                     searchKey="name"
                                     @onSelect="onLegalFirmSelect"
                                     @onUpdate="onLegalFirmUpdate"
                                     :placeholderText="'Legal Firm'"
                                     :isDisabled="isUserRoleAssessor"
                    >
                    </advanced-search>
                    <input type="text" v-else  class="form-control" placeholder=""  :disabled="isUserRoleAssessor  ||isUserRoleLawyer">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }

    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label {
            padding: 8px 15px;
        }
    }
</style>
