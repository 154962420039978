<template>
    <tab :name="itemListName"
         :order="400"
         :counter="countItemsList"
         :id="getTabId"
         :class-name="'tab-content-table'"
         :data-estimate-id="estimate.info.id">
        <items-list :itemslist="estimate.itemsList"
                    :assessment="assessment"
                    :autoSaveItemPart="autoSaveItemPart"
                    :moveItemPart="moveItemPart"
                    :estimate-id="computedEstimateId">
        </items-list>
    </tab>
</template>
<script>
    /*eslint-disable */
    import ItemsList from "./items-list/items-list";

    export default {
        name: 'assessment-items-list-tab',
        props: [
            'estimate',
            'autoSaveItemPart',
            'isOnlyOneEstimate',
            'assessment',
            'moveItemPart',
        ],
        data: function () {
            return {
                name: 'assessment-items-list-tab',
            };
        },
        methods: {},
        computed: {
            computedEstimateId() {
                return this.estimate.info.id || 0;
            },
            countItemsList() {
                let itemList = this.estimate.itemsList;
                let val = 0;
                if (itemList && itemList.RR) {
                    val += itemList.RR.length;
                }
                if (itemList && itemList.Repair) {
                    val += itemList.Repair.length;
                }
                if (itemList && itemList.Paint) {
                    val += itemList.Paint.length;
                }
                if (itemList && itemList.Part) {
                    val += itemList.Part.length;
                }
                if (itemList && itemList.Materials) {
                    val += itemList.Materials.length;
                }
                if (itemList && itemList.Sublet) {
                    val += itemList.Sublet.length;
                }
                if (itemList && itemList.Other) {
                    val += itemList.Other.length;
                }
                if (itemList && itemList.PDR) {
                    val += itemList.PDR.length;

                }
                return val;
            },
            itemListName() {
                //if (this.isOnlyOneEstimate){
                //    return 'Items List';
                //}
                if (this.estimate && this.estimate.info && this.estimate.info.type && this.estimate.info.type == 'estimate') {
                    return 'Est-Items List';
                }
                if (this.estimate && this.estimate.info && this.estimate.info.type && this.estimate.info.type == 'supplement') {
                    return 'Sup-Items List';
                }
                return 'Est-Items List';
            },
            getTabId() {
                return 'assessment-view-items-list-' + this.estimate.info.id;
            }
        },
        components: {
            ItemsList,
        },
    };
</script>
